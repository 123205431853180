<template>
  <avue-form ref="form" v-model="form" :option="option">
    <template #templateName>
      <div class="name-wrapper">
        <span v-if="!isEdit" class="name">{{ form.templateName }}</span>
        <el-input v-else style="width: 400px" size="mini" v-model="form.templateName" clearable></el-input>
        <i class="el-icon-edit edit" @click="onEdit"></i>
      </div>
    </template>
    <template #category>
      {{ currentCategory }}
    </template>
    <template #calculateMode>
      <span>
        {{ mode }}
      </span>
      <el-tooltip placement="top" effect="light">
        <div slot="content" v-if="form.calculateMode !== FRIST_WEIGHT">物流费用 = 费用 + 挂号费 + 紧急情形附加费</div>
        <div slot="content" v-else>
          运费 = 首重价格 +((计费重量-首重)/续重单位重量) * 续费单位价格 + 挂号费 + 紧急情形附加费
        </div>
        <i class="el-icon-question margin"></i>
      </el-tooltip>
    </template>

    <template #isByVolumetricWeight>
      <el-checkbox disabled style="margin-left: 10px" :value="true">实际重量</el-checkbox>
      <el-checkbox v-model="form.isByVolumetricWeight" :true-label="1" :false-label="0"> 计泡重量 </el-checkbox>

      <span class="ml10" v-if="form.isByVolumetricWeight === 1">
        <span>材积除数：</span>
        <el-input size="mini" style="width: 94px" v-model="form.volumeDivisor"></el-input>
      </span>
    </template>

    <template slot="menuForm">
      <el-button type="small" @click="$router.go(-1)">返回</el-button>
    </template>
  </avue-form>
</template>

<script>
import { FRIST_WEIGHT, DOMESTIC_FORMWORK } from '@/utils/constant'

export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      FRIST_WEIGHT,
      isEdit: false,
      option: {
        labelWidth: 'auto',
        menuPosition: 'left',
        submitBtn: false,
        emptyBtn: false,
        submitPostion: 'left',
        size: 'mini',
        span: 24,
        column: [
          {
            label: '模板名称',
            prop: 'templateName',
            labelWidth: 72,
            formslot: true
          },
          {
            label: '物流费用计算方式',
            prop: 'calculateMode',
            labelWidth: 128,
            formslot: true
          },
          {
            label: '模板分类',
            prop: 'category',
            labelWidth: 72,
            formslot: true
          },
          {
            label: '计费重量设置（如勾选计泡，实际费用重量为两者取高）',
            prop: 'isByVolumetricWeight',
            formslot: true
          }
        ]
      }
    }
  },

  computed: {
    mode({ form }) {
      const { calculateMode } = form
      if (calculateMode === FRIST_WEIGHT) {
        return '已选择按首重续重计费'
      }
      return '已选择同重量段费用相同'
    },
    currentCategory() {
      return this.$route.query.category == DOMESTIC_FORMWORK ? '国内模板' : '国际模板'
    }
  },

  methods: {
    onEdit() {
      this.isEdit = !this.isEdit
    },

    formSave() {
      this.edit = false
      const { name, isByVolumetricWeight, volumeDivisor } = this.form
      if (name) {
        this.$message.error('请输入模板名称')
        return false
      }
      if (isByVolumetricWeight === 1) {
        if (isByVolumetricWeight === '') {
          this.$message.error('请输入材积除数')
          return false
        }
        if (volumeDivisor <= 0) {
          this.$message.error('请输入一个大于0的材积除数')
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item {
    margin-bottom: 0;
    height: 32px;
    line-height: 1;
  }
  .el-form-item__label {
    height: inherit;
    font-weight: 400;
    line-height: 32px;
  }
  .el-form-item__content {
    display: flex;
    height: inherit;
    align-items: center;
    line-height: inherit;
  }
}
.edit {
  display: none;
  color: $color-primary;
  margin-left: 10px;
  cursor: pointer;
}
.name-wrapper {
  width: 500px;
  height: inherit;
  line-height: 32px;
  display: inline-block;
}
.name-wrapper:hover {
  .edit {
    display: inline;
  }
}
</style>
